
import { defineComponent, onBeforeMount } from 'vue'

// Components
import SButton from '@/common/components/SButton/index.vue'
import SLoader from '@/common/components/SLoader/index.vue'

// Composable
import { useTitle } from 'vue-composable'
import useComplaint from '@/modules/PretensionsModule/composable/useComplaint'
import { useFile } from '@/common/composable/useFile'

// icons
import iArrowLeft from '@/assets/icons/ArrowLeft.svg'

export default defineComponent({
    components: {
        's-button': SButton,
        's-loader': SLoader,
        'icon-arrow-left': iArrowLeft,
    },
    setup() {
        useTitle('Просмотр жалобы')

        const { complaint_state, loadComplaint, getFileTypeByUrl } = useComplaint()
        const { saveFileByUrl } = useFile()

        onBeforeMount(loadComplaint)

        return { complaint_state, loadComplaint, getFileTypeByUrl, saveFileByUrl }
    },
})
