import { reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// validations
import { required } from '@vuelidate/validators'
import { useFormData } from '@/common/composable/useFormData'

// services
import complaintsAPI from '@/core/new-services/complaints.service'

// types
import { ComplaintFull } from '@/core/new-services/types/complaints'

// constants
const NOTIFICATIONS_GROUP = 'create-module'

// composable
import useVuelidate from '@vuelidate/core'
import useNotifications from '@/common/composable/useNotifications'

export default function () {
    const route = useRoute()
    const router = useRouter()
    const notifications = useNotifications()
    const { getFormData } = useFormData()

    const complaint_state = reactive({
        is_loading: false,
        is_failed: false,
        data: null as ComplaintFull | null,
    })

    const form_state = reactive({
        invoiceNumber: null as string | null,
        title: null as string | null,
        content: null as string | null,
        files: [] as File[],
    })

    const ComplaintValidation = {
        invoiceNumber: { required },
        title: { required },
        content: { required },
        files: { required },
    }
    const form_state$ = useVuelidate(ComplaintValidation, form_state)

    const complaint_id = Number(route.params.complaint_id)

    const loadComplaint = async () => {
        if (!complaint_id) return

        try {
            complaint_state.is_loading = true

            const response = await complaintsAPI.getComplaint(complaint_id)
            complaint_state.data = response.data.data

            complaint_state.is_failed = false
        } catch (error) {
            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка получения данных жалобы',
                },
                error
            )

            complaint_state.is_failed = true
        } finally {
            complaint_state.is_loading = false
        }
    }

    const createComplaint = async () => {
        if (complaint_state.is_loading) return

        form_state$.value.$touch()
        if (form_state$.value.$invalid) return

        try {
            complaint_state.is_loading = true

            const payload = { ...form_state, typeId: 41 }
            await complaintsAPI.createComplaint(getFormData(payload))

            notifications.success({
                group: NOTIFICATIONS_GROUP,
                type: 'success',
                title: 'Жалоба создана',
            })

            router.go(-1)
        } catch (error) {
            console.error(error)

            notifications.failure(
                {
                    group: NOTIFICATIONS_GROUP,
                    type: 'error',
                    title: 'Ошибка создания жалобы',
                },
                error
            )
        } finally {
            complaint_state.is_loading = false
        }
    }

    const getFileTypeByUrl = (url: string): string => {
        const splitted_url = url.split('.')
        return splitted_url[splitted_url.length - 1]
    }

    return {
        complaint_state,
        form_state,
        //
        form_state$,
        //
        complaint_id,
        //
        loadComplaint,
        createComplaint,
        getFileTypeByUrl,
    }
}
